/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS720M
 * 화면 설명: 피보험자 지문촬영 화면
 */
<template>
  <ur-page-container class="msp" title="피보험자 지문촬영" :show-title="true" type="subpage" :topButton="true"
      @on-header-left-click="fn_HeaderBackBtnHandler">    

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
          <span class="fs22rem fwb">고객확인 사항</span>
          <div><span class="fs20rem crTy-blue2 fwb mr2">3</span>/<span class="ml2">3</span></div>
        </ur-box-container>
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 pb20">
          <div class="fs18rem fwm full">
            <span>고객확인 사항</span>
          </div>
          <div class="mt10">
            <ul class="terms-list-area crTy-bk7 fs14rem">
              <li>아래 계약 내용을 확인하시고, 반드시 고객이 직접 동의 후 지문 촬영을 진행해 주시기 바랍니다.</li>
            </ul>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column" class="bd-b-Ty1 bd-T-Ty1 mt10 ">
            <div class="full fexTy2 bd-b-Ty2 pt20 pb10" v-for="item in itemList" :key="item.index">
              <span class="fs16rem min100">{{ item.title }}</span>
              <span class="fs16rem fwb ml20">{{ item.content }}</span>
            </div>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt16 pb16 bd-b-Ty1 bd-T-Ty1 mt30">
          <div class="chk-box">
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.isAllAgree" @input="fn_OnSelectAgreeAll(1)" :disabled="complete === true || lv_DisableAgree === true" ><span class="fs16rem fwb">고객확인 사항 전체동의</span></mo-checkbox>
            </div>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 pb20 bd-b-Ty1 bgcolor-3">
          <div class="chk-box">
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="1" :disabled="complete === true || lv_DisableAgree === true" class="align-item-start">
                <span class="fs14rem crTy-bk7">(필수)본인은 본 보험계약의 주계약, 특약 보장내용, 보험가입금액, 보험기간, 보험수익자 등 보험계약 주요사항에 대하여 보험계약 체결 과정을 통하여 확인하였습니다.</span>
              </mo-checkbox>
            </div>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 pb20 bd-b-Ty1 bgcolor-3">
          <div class="chk-box">
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="2" :disabled="complete === true || lv_DisableAgree === true" class="align-item-start">
                <span class="fs14rem crTy-bk7">(필수)본인은 본 보험계약에서 약관상 보험사고가 발생하는 경우 보험수익자에게 약정한 보험금이 지급됨을 충분히 이해하고 보험계약 체결에 동의합니다.</span>
              </mo-checkbox>
            </div>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 pb20 bd-b-Ty1 bgcolor-3">
          <div class="chk-box">
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="3" :disabled="complete === true || lv_DisableAgree === true" class="align-item-start">
                <span class="fs14rem crTy-bk7">(필수)본인은 본 보험계약사항에 대하여 충분히 설명을 받고 이해하였으며, 계약자와 피보험자가 다른 본 계약에서 피보험자로서 계약체결을 진행하는 것에 동의합니다.</span>
              </mo-checkbox>
            </div>
          </div>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div" v-if="complete === false && test==='1'">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" :disabled="!cardOne.isMustAgree === true || lv_EnableTablet === false"  @click="fn_ProcPhotoTablet(false)">지문 촬영 진행</mo-button>
          </div>
          <div class="relative-div" v-if="complete === true">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_NextProcess">확인</mo-button>
          </div>
          <div class="relative-div" v-if="test==='2'">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ProcPhotoTablet(false)">지문 촬영 재진행</mo-button>
          </div>
          <div class="relative-div" v-if="test==='3'">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_FailCfmOpen('PHOTOFAIL')">닫기</mo-button>
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ProcPhotoTablet(false)">지문 촬영 재진행</mo-button>
          </div>
          <div class="relative-div" v-if="test==='4'">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_FailCfmOpen('REGFAIL')">닫기</mo-button>
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ReTryRegData">등록 재시도</mo-button>
          </div>
        </ur-box-container>
      </template>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import MSPCM300P from '@/ui/cm/MSPCM300P'
import Msg from '@/systems/webkit/msg/msg'


export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS720M', 
  screenId: 'MSPPS720M', 
  components: {
    'MSPCM300P': MSPCM300P,
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      bioParams:{},
      //lv_ShowBottom: false, // 하단 Bar
      //lv_IsShowCustTelConfirm: false, // 고객 전화번호없을경우 출력 메시지
      //lv_ShowTSSCM122D: false, // 피보험자 고객카드
      lv_InsRdChnlCustId: '', // 피보험자 채널고객아이디
      lv_CnsltNo: '', // 모집컨설턴트 번호
      //lv_CustCardConfirm: false, // 고객카드오픈 컨펌 여부
      lv_MsgCustCardConfirm: this.$t('ps')['EPSC040'], // 고객카드 오픈 컨펌 메지시
      lv_MsgFofAcpnConfirm: this.$t('ps')['EPSC041'], // 지점접수확인 메시지
      //lv_TblPhotoConfirm: false,
      lv_MsgTblPhotoConfirm: this.$t('ps')['EPSC044'], // 태블릿 촬영 확인 메시지
      //lv_FailFofAcpnConfirm: false, // 촬영실패로 인한 지점접수 확인
      //lv_FailFofAcpnConfirm2: false, // 부인방지실패로 인한 확인 메시지
      lv_FailFofAcpnReason: '', // 지점 접수 사유
      lv_MsgFailFofAcpnConfirm: this.$t('ps')['EPSC045'], // 촬영실패로 인한 지점접수확인 메시지
      //lv_RtnPhotoConfirm: false, // 촬영결과 확인
      lv_MsgRtnPhotoConfirm: this.$t('ps')['EPSC049'], // 촬영결과 상태조회 메시지
      //lv_RtnPhotoConfirm2: false, // 촬영결과2번 실패시확인
      lv_MsgRtnPhotoConfirm2: this.$t('ps')['EPSC058'], // 촬영결과2번 실패시확인 메시지
      //lv_Alert: false, // 일반 Alert 메시지여부
      //lv_MsgPushSend: false, // 푸시전송 여부Confirm창
      lv_MsgType: 'SMP', // 일반 Alert 메시지
      //lv_MsgAlert: '', // 일반 Alert 메시지
      lv_BioAllData: {}, // 사전조회전체데이타
      // lv_EnableMini: true, // 지문촬영버튼활성화
      lv_EnableTablet: true, // 태블릿촬영버튼활성화
      lv_FailCount: 0, // 촬영 실패 건수
      // lv_ShowTestCombo: false, // 테스트용콤보박스 출력여부
      lv_DisableAgree: false,
      lv_IngPolling: false,
      lv_RegData1: {}, // 지문등록실패시 다시시도를 위한 데이타1
      lv_RegData2: {}, // 지문등록실패시 다시시도를 위한 데이타2
      complete: false, // 여기서부터는 퍼블쪽에서 준것으로 수정 안함.
      // showComponent: false,
      // isShowLoading: false, // 로딩 메시지
      title: '피보험자 지문촬영',
      cardOne: {
        allItems: ['1', '2', '3', '4'],
        agreeCheckboxList: [],
        isAllAgree: false,
        isMustAgree: false
      },
      itemList: [
        {
          title: '상품명',
          content: ''
        },
        {
          title: '청약(설계)번호',
          content: '',
          normalLetter: true,
          half: 'left'
        },
        {
          title: '청약일자',
          content: '',
          half: 'right'
        },
        {
          title: '계약자',
          content: '',
          half: 'left'
        },
        {
          title: '피보험자',
          content: '',
          half: 'right'
        },
        // {
        //   title: '종피보험자',
        //   content: '',
        //   half: 'left'
        // },
        {
          title: '수익자',
          content: '',
          half: 'right'
        }
      ],
      test: '1'
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.routerParam = this.$route.params
    console.log('###################### 넘어온 Param')
    console.log(JSON.stringify(this.routerParam))

    this.fn_OnInit() // 계약정보+고객정보+지문인식기앱초기정보
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
   'cardOne.agreeCheckboxList' (newValue) { // 전체동의 여부 확인
      if (newValue.includes('1') && newValue.includes('2') && newValue.includes('3')) {
        this.cardOne.isMustAgree = true
        this.cardOne.isAllAgree = true
      } else {
        this.cardOne.isMustAgree = false
        this.cardOne.isAllAgree = false
      }
    },
    // lv_ShowTSSCM122D (newValue) { // 고객 카드 종료시 처리
    //   if (!newValue) {
    //     this.fn_CloseCustCardPopup()
    //   }
    // }

  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    }, //fn_DevicBackBtnHandler

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : Header Back 타이틀 클릭 핸들러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: '피보험자 지문촬영을 중단하시겠습니까?',
         title_pos_btn: "예",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {tabIdx: 1})
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
     * Function명  : fn_OnInit
     * 설명        : 계약 정보 및 기등록고객 정보 조회
     *      bioSvcTp :  1 = 전자서명
     *                  2 = 개별등록
     *                  3 = 전자서명검증
     ******************************************************************************/
    fn_OnInit: function () {
      // TSSPS234M에서 받은 변수
      this.bioParams = this.$route.params
      if ( !this.bioParams || this.bioParams == null || _.isEmpty(this.bioParams.vuchId) ) {
        this.getStore('confirm').dispatch('ADD', '호출 파라메타 없음 !!')
        return
      }
      let lv_Vm = this
      const trnstId = 'txTSSPS40S1'
      const auth = 'S'
      let pParams = {}
      pParams = {bioSvcTp: '1', bioSvcId: this.bioParams.vuchId} // 영수증ID
      pParams.agreeYn = 'Y' // 전자서명앱에서 넘어오는 동의여부
      pParams.vuchId = pParams.bioSvcId // 영수증ID
      try {
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.body) {
            let rtnData = response.body
            rtnData.bioData = { // 생체기본정보
              bioComyCd: rtnData.bioComyCd,
              bioScCd: rtnData.bioScCd,
              bioSvcTp: rtnData.bioSvcTp,
              bioSvcId: rtnData.bioSvcId,
              bioDefaultFinger: rtnData.bioDefaultFinger,
              bioLicenseKey: rtnData.bioLicenseKey,
              bioPublicKey: rtnData.bioPublicKey,
              bioPublicKeyVersion: rtnData.bioPublicKeyVersion,
              custInfoCntnt: rtnData.custInfoCntnt
            }
            lv_Vm.fn_OnInitCallBack(rtnData)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          lv_Vm.fn_trace('error', 'MSPPS720M.fn_OnInit ::' + error )
          window.vue.error(error)
        })
      } catch (error) {
        lv_Vm.fn_trace('error', 'MSPPS720M.fn_OnInit ::' + error )
        window.vue.error(error)
      }
    },
    /******************************************************************************
     * Function명  : fn_OnInitCallBack
     * 설명        : fn_OnInit 콜백
     *          (1) 조회 데이타 화면 출력
     *          (2) 고객(피보험자) 전화번호 유무확인
     *              - 전화번호 없을경우 고객카드팝업(TSSCM122D) 오픈
     ******************************************************************************/
    fn_OnInitCallBack: function (rtnData) {
      /* (1) 조회데이타 화면출력 */
      this.lv_BioAllData = rtnData
      this.itemList[0].content = this.lv_BioAllData.vuhdInfo.mnPrdtNm // 상품명
      this.itemList[1].content = this.lv_BioAllData.vuhdInfo.vuchId // 전자서명설계ID
      this.itemList[2].content = this.lv_BioAllData.vuhdInfo.elstPblYmd // 전자서명발행일자(청약일자)
      this.itemList[2].content = new Date().toISOString().substring(0, 10)
      this.itemList[3].content = this.lv_BioAllData.vuhdInfo.mnContrNm // 계약자명
      this.itemList[4].content = this.lv_BioAllData.vuhdInfo.insrdNm // 주피보험자명

      if ( !this.bioParams || this.bioParams == null || _.isEmpty(this.bioParams.bnfrInfo) ) {
        this.getStore('confirm').dispatch('ADD', '호출 파라메타 없음 !!')
      } else {
        // 종피 삭제
        // this.itemList[5].content = this.bioParams.hoshInfo
        // this.itemList[6].content = this.bioParams.bnfrInfo
        this.itemList[5].content = this.bioParams.bnfrInfo
      }
     
      /* (2) 고객(피보험자) 전화번호 유무확인 */
      if (this.$bizUtil.isEmpty(this.lv_BioAllData.custInfo.celno)) { // 피보험자 전화번호가 없을 경우
        // this.lv_CustCardConfirm = true
        this.fn_CustCardConfirm(this.lv_MsgCustCardConfirm)
      }
    },
    /******************************************************************************
     * Function명  : fn_OnSelectAgreeAll
     * 설명        : 전체 동의 여부 확인
     ******************************************************************************/
    fn_OnSelectAgreeAll (cardNumber) {
      if (cardNumber === 1) {
        this.cardOne.agreeCheckboxList = this.cardOne.isAllAgree ? this.cardOne.allItems : []
      } else {
        this.cardTwo.agreeCheckboxList = this.cardTwo.isAllAgree ? this.cardTwo.allItems : []
      }
    },
    /******************************************************************************
     * Function명  : fn_CustCfmResult
     * 설명        : 고객카드 전화번호 수정여부 컨펌창 CallBack함수
     * isOpen (수정여부)
     *  - true : 고객카드팝업 오픈(TSSCM122D)
     *  - false : 지점 접수 처리 ( fn_FailCfmOpen('CUSTFAIL') )
     ******************************************************************************/
    fn_CustCfmResult (isOpen) {
      if (isOpen) {
        this.lv_InsRdChnlCustId = this.lv_BioAllData.vuhdInfo.insrdChnlCustId
        this.lv_CnsltNo = this.lv_BioAllData.vuhdInfo.clctCnsltNo
        //this.lv_ShowTSSCM122D = true
        this.fn_showTSSCM122D()
      } else {
        this.fn_FailCfmOpen('CUSTFAIL')
      }
    },
    /******************************************************************************
     * Function명  : fn_FailCfmOpen
     * 설명        : 지점접수 처리 Case 별 컨펌창 오픈
     *              'CUSTFAIL' 고객전화번호 없음 인한 지점접수 확인창 오픈
     *              'PHOTOFAIL' 지문촬영 실패로 인한 지점접수 확인창 오픈
     *              'REGFAIL' 지문등록 실패로 인한 지점접수 확인창 오픈
     ******************************************************************************/
    fn_FailCfmOpen ( sData ) {
      this.lv_FailFofAcpnReason = sData
      switch ( sData ) {
        case 'CANCEL' :
          //this.lv_MsgFailFofAcpnConfirm = '지문촬영을 취소하시겠습니까 ?'
          //this.lv_FailFofAcpnConfirm = true
          this.fn_FailFofAcpnConfirm('CANCEL','지문촬영을 취소하시겠습니까 ?')
          break
        case 'CUSTFAIL' : // 고객 전화번호 없음
          //this.lv_MsgFailFofAcpnConfirm = this.$t('ps')['EPSC041'] // 고객전화번호 없음
          //this.lv_FailFofAcpnConfirm = true
          this.fn_FailFofAcpnConfirm('CUSTFAIL', this.$t('ps')['EPSC041'])
          break
        case 'PHOTOFAIL' : // 지문촬영 실패
        case 'REGFAIL' : // 지문등록 실패
          //this.lv_MsgFailFofAcpnConfirm = '지문촬영에 실패하였습니다.<br><span style="color:#FE886A;">피보험자 서면동의서 작성 후<br>서면동의서 촬영 혹은 지점접수</span>를 진행해 주세요.'
          //this.lv_FailFofAcpnConfirm = true
          this.fn_FailFofAcpnConfirm('REGFAIL', '지문촬영에 실패하였습니다.<br><span style="color:#FE886A;">피보험자 서면동의서 작성 후<br>서면동의서 촬영 혹은 지점접수</span>를 진행해 주세요.')
          break
        default:
          break
      }
    },
    /******************************************************************************
     * Function명  : fn_FofAcpnCfmResult
     * 설명        : 지점접수 오픈 컨펌창 결과 (fn_FailCfmOpen CallBack) 함수
     * isResult ( 처리결과 ) : true / false
     *  - true : 지접접수 처리 = TSSPS180M.fn_Child186DEvent('FAIL1') 호출
     *  - false : 지점접수 취소 ==> 모바일생체인증에서는 닫기로 변경후 중단건화면조회로 이동.
     ******************************************************************************/
    fn_FofAcpnCfmResult (isResult) {
      if (isResult) { // 지점접수처리
        this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {tabIdx: 1})
      } else { // 지점 접수 취소
        switch ( this.lv_FailFofAcpnReason ) {
          case 'CUSTFAIL' : // 고객 전화번호 없음 취소
            //this.lv_CustCardConfirm = true
            this.fn_CustCardConfirm(this.lv_MsgCustCardConfirm)
            break
          case 'PHOTOFAIL' : // 지문촬영 실패
            break
          case 'REGFAIL' : // 지문등록 실패
            break
          default:
            break
        }
      }
    },
    /******************************************************************************
     * Function명  : fn_ProcPhotoMini
     * 설명        : 지문촬영 (사랑온 미니에서 촬영)을 위한 푸시발송
     *               (1) txTSSPS40U1 호출 : 사랑온미니(컨설턴트) Push 전송.
     *               (2) Push 정상전송시 : fn_MsgAlertResult('PUSHOK') 호출
     *               (3) Push 발송실패시 : 관련메시지 출력, 관련 버튼 비활성화
     ******************************************************************************/
    fn_ProcPhotoMini () {
      this.lv_DisableAgree = true // 고객동의사항 수정불가처리.
      this.lv_EnableTablet = false
      let lv_Vm = this
      const trnstId = 'txTSSPS40U1'
      const auth = 'S'
      let pParams = {bioSvcTp: lv_Vm.lv_BioAllData.bioData.bioSvcTp, bioSvcId: lv_Vm.lv_BioAllData.bioData.bioSvcId}
      this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
        if (response.body) {
          if (!lv_Vm.lv_IngPolling) { // 폴링중이 아닐때만 폴링여부 확인창오픈
            if ( response.body.isPushYN === 'Y' ) { // 푸시정상적으로 전송
              lv_Vm.lv_DisableAgree = true // 동의항목 수정불가
              //lv_Vm.lv_Alert = true
              lv_Vm.lv_MsgType = 'PUSHOK'
              //lv_Vm.lv_MsgAlert = lv_Vm.$t('ps')['EPSC042']
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ps')['EPSC042'])
              lv_Vm.fn_MsgAlertResult(lv_Vm.lv_MsgType)
            } else { // 푸시 실패 또는 연결되 휴대폰 없음
              //lv_Vm.lv_Alert = true
              lv_Vm.lv_MsgType = 'PUSHFAIL'
              //lv_Vm.lv_MsgAlert = lv_Vm.$t('ps')['EPSC043']
              // lv_Vm.lv_EnableMini = false
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ps')['EPSC043'])
              lv_Vm.fn_MsgAlertResult(lv_Vm.lv_MsgType)
            }
          } else {
            //lv_Vm.lv_MsgAlert = lv_Vm.$t('ps')['EPSC042']
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('ps')['EPSC042'])
            //lv_Vm.lv_MsgPushSend = true
            lv_Vm.fn_MsgPushSendResult(lv_Vm.lv_MsgType)
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
     * Function명  : fn_MsgAlertResult
     * 설명        : fn_ProcPhotoMini 전송 콜백 함수
     * sData (푸시 전송 결과)
     *  - PUSHOK  : 등록 진행 상태 조회 (fn_GetCurStatus)
     *  - PUSHFAIL : 관련 버튼 활성화 및 비활성화
     ******************************************************************************/
    fn_MsgAlertResult (sData) {
      if (sData === 'PUSHOK') {
        this.lv_FailCount = 0
        this.fn_GetCurStatus() // 현재 상태조회 호출
      } else if (sData === 'PUSHFAIL') {
        // this.lv_EnableMini = false
        this.lv_EnableTablet = true
      }
    },
    /******************************************************************************
     * Function명  : fn_MsgPushSendResult
     * 설명        : fn_ProcPhotoMini 전송 콜백 함수
     *               메시지 전송후 후방 Dim 처리.
     ******************************************************************************/
    fn_MsgPushSendResult () {
      this.getStore('progress').dispatch('ADD')
    },
    /******************************************************************************
     * Function명  : fn_ProcPhotoTablet
     * 설명        : 지문촬영을 태블릿에서 진행 할것인지 컨펌창 오픈
     *      (1) callBack : fn_TblPhotoCfmResult()에서 후속처리
     ******************************************************************************/
    fn_ProcPhotoTablet ( bData ) {
      // this.lv_TblPhotoConfirm = true // 태블릿 촬영 확인창 오픈
      this.fn_ProcPhotoTabletConfirm(this.lv_MsgTblPhotoConfirm) // 태블릿 촬영 확인창 오픈
    },
    /******************************************************************************
     * Function명  : fn_TblPhotoCfmResult
     * 설명        : 태블릿에서 촬영여부 확인창의 결과
     *     (1) fn_ProcPhotoTablet CallBack 함수
     *     (2) bData (확인결과)
     *         - true : 지문인식기 앱 실행 (fn_OpenJiMunApp)
     *         - false : 관련 버튼 활성화
     ******************************************************************************/
    fn_TblPhotoCfmResult (bData) {
      if ( bData ) {
        this.lv_DisableAgree = true // 동의항목 수정불가
        // this.lv_EnableMini = false
        this.fn_OpenJiMunApp(true)
      } else {
        // this.lv_EnableMini = true
        this.lv_EnableTablet = true
      }
    },
    /******************************************************************************
     * Function명  : fn_RtnPhotoCfmResult
     * 설명        : 사랑온 미니에서의 촬영(등록) 결과 확인 실패시 재시도 확인창의 CallBack 함수
     * bData (확인결과)
     *  - true : 재시도 (촬영및등록상태 재조회 fn_GetCurStatus)
     *  - false : 재촬영을 위한 관련버튼 활성화
     ******************************************************************************/
    fn_RtnPhotoCfmResult (bData) {
      if ( bData ) { // 다시시도
        this.fn_GetCurStatus()
      } else { // 아니오
        // this.lv_EnableMini = true
        this.lv_EnableTablet = true
      }
    },
    /******************************************************************************
     * Function명  : fn_RtnPhotoCfmResult2
     * 설명        : 사랑온 미니에서의 촬영(등록) 결과 확인 2번이상 미응답,실패시 재시도 확인창의 CallBack 함수
     * bData (확인결과)
     *  - true : 재시도 (촬영및등록상태 재조회 fn_GetCurStatus)
     *  - false : 지접접수처리
     ******************************************************************************/
    fn_RtnPhotoCfmResult2 (bData) {
      if ( bData ) { // 다시시도
        this.fn_GetCurStatus()
      } else { // 아니오
        this.fn_FofAcpnCfmResult(true) // 지점접수
      }
    },
    /******************************************************************************
     * Function명  : fn_OpenJiMunApp
     * 설명        : 지문인식앱 실행
     * 관련 파라메타
     *          bioComyCd: rtnData.bioComyCd,
                bioScCd: rtnData.bioScCd,
                bioSvcTp: rtnData.bioSvcTp,
                bioSvcId: rtnData.bioSvcId,
                bioDefaultFinger: rtnData.bioDefaultFinger,
                bioLicenseKey: rtnData.bioLicenseKey,
                bioPublicKey: rtnData.bioPublicKey
                var captureOption = {}
                captureOption.captureMode = 'enroll' 또는 'verify' // enroll - 인증, verify - 등록
                captureOption.repeatCnt = '1' 에서 '3' // 반복 횟수 - 인증시에만 적용됨 (String)
                captureOption.serviceCode = '등록/인증/서명/일괄'
                captureOption.serviceId = '고객ID/영수증ID' // 등록/인증 시 -> 고객 ID, 전자서명 시 -> 영수증ID
                captureOption.userType = '피보험자/계약자/수익자/보호자/기타'
                captureOption.systemMessage = '문구' // 비접촉 촬영화면에 출력할 서비스 안내 메세지(예, 홍길동님 안녕하세요~)
                captureOption.gender = 'male' 또는 'female' // 성별
                captureOption.age = '나이' // 나이 숫자를 넣습니다. (String)
                captureOption.fingerMode = '1' 에서 '10' // '1', ... '5' : 오른손 엄지부터~소지까지, // '6', ... '10' : 왼손 엄지부터~소지까지
                captureOption.useEnrolledPrivateInfo: 'true' 혹은 'false' // 등록-인증 연속 촬영시 등록UI에서 선택한 개인화 값(성별, 나이, 손가락번호)를 연속되는 인증 촬영에도 사용할지 여부
     ******************************************************************************/
    fn_OpenJiMunApp (pData) {
      let lv_Vm = this
      let args = {}
      let tmpObj = {}
      args.serialNo = lv_Vm.lv_BioAllData.bioData.bioLicenseKey
      args.siteId = lv_Vm.lv_BioAllData.bioData.bioComyCd
      args.authCode = lv_Vm.lv_BioAllData.bioData.bioScCd
      args.publicKeyVer = lv_Vm.lv_BioAllData.bioData.bioPublicKeyVersion
      args.publicKey = lv_Vm.lv_BioAllData.bioData.bioPublicKey
      args.captureOptions = []
      tmpObj.captureMode = 'enroll' // 등록
      tmpObj.repeatCnt = '1' // 반복횟수
      tmpObj.serviceCode = '등록' // 등록/인증/서명/일괄
      tmpObj.serviceId = lv_Vm.lv_BioAllData.vuhdInfo.vuchId // 영수증ID or 고객ID
      tmpObj.userType = '피보험자' // 피보험자/계약자/수익자/보호자/기타
      tmpObj.systemMessage = lv_Vm.lv_BioAllData.bioData.custInfoCntnt // ex) 촬영 대상자 : 홍길동<br>최근 서명된 지문 : 2020년 05월 27일 왼손엄지
      tmpObj.gender = lv_Vm.$bizUtil.getGenderFromKnb(lv_Vm.lv_BioAllData.custInfo.knb) // 성별
      tmpObj.age = lv_Vm.$bizUtil.getAgeFromKnb(lv_Vm.lv_BioAllData.custInfo.knb) + '' // 나이
      tmpObj.fingerMode = lv_Vm.lv_BioAllData.bioData.bioDefaultFinger // 손가락 번호 - 오른손엄지('1')~약지('5'), 왼손엄지('6')~약지('10')
      tmpObj.systemTextColor = 'FF58B7FF' // 강조 및 포인트 글자 색상 (기본값 : FF58B7FF)
      tmpObj.systemButtonColor = 'FF0971E8' // 버튼 색상 (기본값 : FF0971E8)
      args.captureOptions.push(tmpObj)
      lv_Vm.fn_trace('info', '지문인식기 앱 호출 :: fn_OpenJiMunApp.args', args)
      
      // this.fn_NextProcess()
      // this.complete = true
      // this.test = '1'
      // this.getStore('toast').dispatch('ADD', '지문 촬영이 성공적으로 진행 되었습니다.')
      // return;
      // 지문앱 실행
      window.fdpbridge.exec('startCaptureFingerPrintPlugin', args, (result) => {
        if (result && result.data && result.data.state) {
          lv_Vm.fn_trace('info', '지문인식기 앱 호출결과 리턴', result.data)
          if (result.data.state === 'success') {
            if (result.data.results.length >= 1) {
              lv_Vm.getStore('toast').dispatch('ADD', '촬영성공 되었습니다. 등록요청 진행중입니다...')
              let pParams = {bioSvcTp: '1', bioSvcId: lv_Vm.lv_BioAllData.vuhdInfo.vuchId}
              lv_Vm.lv_RegData1 = result.data
              lv_Vm.lv_RegData2 = pParams
              lv_Vm.fn_RegTemplateData(lv_Vm.lv_RegData1, lv_Vm.lv_RegData2, lv_Vm.fn_RegDataCallback) // 지문등록 요청
            } else { // 반환값(지문데이타) 비정상 = 지점접수 유도화면
              lv_Vm.getStore('toast').dispatch('ADD', '촬영실패 되었습니다. (지문데이타가 이상합니다.)')
              lv_Vm.lv_FailCount++
              if ( lv_Vm.lv_FailCount === 1 ) { // 첫번째 실패시
                lv_Vm.test = '2' // [다시촬영]
                lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 실패하였습니다.')
              } else { // 두번째 이상 실패시
                lv_Vm.test = '3' // [다시촬영][지점접수]
                lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 연속 실패하였습니다.')
                lv_Vm.getStore('toast').dispatch('ADD', '주의사항 확인 후 재촬영을 진행하시거나 피보험자의 서면동의서 작성 후 서면동의서 촬영 혹은 지점접수 진행 바랍니다.')
              }
              // lv_Vm.showComponent = true
            }
          } else { // 촬영실패 또는 촬영취소 = 지점접수 유도화면
            // var detail = result.data.results.filter(e => e.state === 'failed')[0].detail // 실패한 항목의 상세 내역
            // lv_Vm.getStore('toast').dispatch('ADD', '촬영 실패 : ' + detail)
            lv_Vm.getStore('toast').dispatch('ADD', '촬영실패,취소 되었습니다.')
            lv_Vm.lv_FailCount++
            if ( lv_Vm.lv_FailCount === 1 ) { // 첫번째 실패시
              lv_Vm.test = '2' // [다시촬영]
              lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 실패하였습니다.')
            } else { // 두번째 이상 실패시
              lv_Vm.test = '3' // [다시촬영][지점접수]
              lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 연속 실패하였습니다.')
              lv_Vm.getStore('toast').dispatch('ADD', '주의사항 확인 후 재촬영을 진행하시거나 피보험자의 서면동의서 작성 후 서면동의서 촬영 혹은 지점접수 진행 바랍니다.')
            }
            // lv_Vm.showComponent = true
          }
        } else { // 반환값 비정상 = 지점접수 유도화면
          lv_Vm.fn_trace('error', 'startCaptureFingerPrintPlugin 호출 에러 :: 리턴 데이타가 없습니다.')
          lv_Vm.getStore('toast').dispatch('ADD', '촬영실패 되었습니다.')
          lv_Vm.lv_FailCount++
          if ( lv_Vm.lv_FailCount === 1 ) { // 첫번째 실패시
            lv_Vm.test = '2' // [다시촬영]
            lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 실패하였습니다.')
          } else { // 두번째 이상 실패시
            lv_Vm.test = '3' // [다시촬영][지점접수]
            lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 연속 실패하였습니다.')
            lv_Vm.getStore('toast').dispatch('ADD', '주의사항 확인 후 재촬영을 진행하시거나 피보험자의 서면동의서 작성 후 서면동의서 촬영 혹은 지점접수 진행 바랍니다.')
          }
          // lv_Vm.showComponent = true
        }
      }, ( error ) => { // 앱 호출 에러 = 지점접수 유도화면
        lv_Vm.fn_trace('error', 'startCaptureFingerPrintPlugin error', error )
        lv_Vm.getStore('toast').dispatch('ADD', '지문인식기앱 호출에 실패하였습니다.')
        lv_Vm.lv_FailCount++
        if ( lv_Vm.lv_FailCount === 1 ) { // 첫번째 실패시
          lv_Vm.test = '2' // [다시촬영]
          lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 실패하였습니다.')
        } else { // 두번째 이상 실패시
          lv_Vm.test = '3' // [다시촬영][지점접수]
          lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 연속 실패하였습니다.')
          lv_Vm.getStore('toast').dispatch('ADD', '주의사항 확인 후 재촬영을 진행하시거나 피보험자의 서면동의서 작성 후 서면동의서 촬영 혹은 지점접수 진행 바랍니다.')
        }
        // lv_Vm.showComponent = true
      })
    },
    /******************************************************************************
     * Function명  : fn_GetCurStatus
     * 설명        : 등록 진행상태 확인 (금결원 위탁등록이 정상적인지 확인)
     * sCurStatusCd (진행상태)
     *  - 03 : 금결원 위탁등록이 완료되어 지문의 티켓,영수증을 금결원과 삼성생명 분할보관상태
     ******************************************************************************/
    fn_GetCurStatus ( sData ) {
      this.lv_IngPolling = true // 풀링중
      let lv_Vm = this
      const trnstId = 'txTSSPS41S1'
      const auth = 'S'
      let pParams = {vuchId: lv_Vm.lv_BioAllData.vuhdInfo.vuchId, // 영수증아이디
        elstInsrdPartnNo: lv_Vm.lv_BioAllData.vuhdInfo.elstInsrdPartnNo, // 고객파트너번호
        insrdChnlCustId: lv_Vm.lv_BioAllData.vuhdInfo.insrdChnlCustId, // 채널고객아이디
        liveAthntCustScCd: '02' // 고객구분코드
      }
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        // lv_Vm.isShowLoading = false
        if (response.body) {
          lv_Vm.lv_IngPolling = false // 풀링중
          let sCurStatusCd = response.body.curStatusCd
          if (sCurStatusCd === '03') { // 등록,인증 완료 상태일때
            // [ASR201100532] 인증/등록 완료시 시스템에서 [확인] 버튼 클릭 처리.
            // if ( lv_Vm.lv_EnableMini === false ) {
            //   lv_Vm.showComponent = true
            // } else {
            //   lv_Vm.complete = true
            // }
            lv_Vm.complete = true
            lv_Vm.test = '1'
            lv_Vm.getStore('toast').dispatch('ADD', '지문 촬영이 성공적으로 진행 되었습니다.')
      
            // lv_Vm.fn_NextProcess()
          } else {
            lv_Vm.lv_FailCount++ // 등록실패건수
            lv_Vm.fn_trace('info', 'fn_GetCurStatus : lv_FailCount', lv_Vm.lv_FailCount )
            if ( lv_Vm.lv_FailCount < 2 ) { // 첫번째 실패시
              // lv_Vm.lv_RtnPhotoConfirm = true // 확인창오픈 : EPSC048": "촬영 결과를 확인할 수 없습니다.\n다시 시도 하시겠습니까?
              lv_Vm.fn_RtnPhotoConfirm(lv_Vm.lv_MsgRtnPhotoConfirm)
            } else { // 두번째 이상 실패시
              //lv_Vm.lv_RtnPhotoConfirm2 = true // 확인창오픈 : EPSC058
              lv_Vm.fn_RtnPhotoConfirm2(lv_Vm.lv_MsgRtnPhotoConfirm2)
            }
          }
        }
      }).catch(function (error) {
        lv_Vm.lv_IngPolling = false // 풀링중
        window.vue.error(error)
      })
    },
    /******************************************************************************
     * Function명  : fn_NextProcess
     * 설명        : 정상 처리후 [확인]버튼 클릭시 다음 프로세스 처리
     *               부모화면(TSSPS180M으로 성공 메시지 전송)
     *               TSSPS180M.fn_Child186DEvent('SUCCESS') 호출
     * 모바일청약 : 정상처리 완료 후 부인방지 처리.
     * fileHash = vuchId
     ******************************************************************************/
    fn_NextProcess: function () {
      // this.getStore('toast').dispatch('ADD', '등록완료 되었습니다.다음 업무 진행중입니다...')
      // this.showComponent = true
      this.test = '5' // 지점접수시 백화면은 공백으로
      this.fn_trace('info', 'MSPPS720M.fn_NextProcess :: 정상처리 됨')

      this.fn_prcsInsrdCnsntResult()
      // this.$emit('childs-186D-event', 'SUCCESS') // 전자서명 완료 처리
    },
    /******************************************************************************
     * Function명  : fn_ReTryRegData
     * 설명        : 등록 실패시 지문정보 재등록요청
     *  - fn_RegTemplateData 호출
     ******************************************************************************/
    fn_ReTryRegData () {
      this.fn_RegTemplateData( this.lv_RegData1, this.lv_RegData2, this.fn_RegDataCallback) // 재등록요청
    },
    /******************************************************************************
     * Function명  : fn_RegDataCallback
     * 설명        : 등록,인증 요청 콜백 함수
     * oData (결과 Object)
     *  - oData.isOk=true : 등록요청 성공(지문정상등록)
     *  - oData.isOk=false : 등록요청 실패 : 재시도,지점접수 버튼활성화
     ******************************************************************************/
    fn_RegDataCallback: function (oData) {
      this.fn_trace('info', '지문등록,인증 요청 콜백 MSPPS720M.fn_RegDataCallback.oData ::', oData)
      if ( oData.isOk ) { // 등록요청 성공
        this.complete = true
        this.test = '1'
        this.getStore('toast').dispatch('ADD', '지문 촬영이 성공적으로 진행 되었습니다.')
      
        // this.fn_NextProcess() // [확인] 버튼 클릭없이 다음프로세스 진행
      } else { // 등록요청 실패
        this.test = '4' // 등록인증 실패처리
        this.getStore('toast').dispatch('ADD', '지문 등록 및 인증에 실패 하였습니다. 지속적인 실패 시, 피보험자의 서면동의서 작성 후서면동의서 촬영 혹은 지점접수 진행 바랍니다.')
        // this.showComponent = true
      }
    },
    /******************************************************************************
     * Function명  : fn_CloseCustCardPopup
     * 설명        : 고객카드팝업 종료시 처리 이벤트
     *               - 고객 카드에서 전화번호가정상적으로 갱신되었는지 유무 확인
     *               - isProcOk === 'Y' 정상처리
     *                 else : 고객 전화번호 미처리 확인창 오픈
     ******************************************************************************/
    fn_CloseCustCardPopup: function () {
      let lv_Vm = this
      const trnstId = 'txTSSPS40S2'
      const auth = 'S'
      let pParams = {clctCnsltNo: lv_Vm.lv_BioAllData.vuhdInfo.clctCnsltNo, // 모집컨설턴트번호
        insrdChnlCustId: lv_Vm.lv_BioAllData.vuhdInfo.insrdChnlCustId // 채널고객아이디
      }
      // this.isShowLoading = true
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        if (response.body) {
          let isProcOk = response.body.isProcOk
          if (isProcOk === 'Y') { // 등록,인증 완료 상태일때
          } else {
            //lv_Vm.lv_CustCardConfirm = true // 고객 전화번호 미처리 확인창 오픈
            this.fn_CustCardConfirm(this.lv_MsgCustCardConfirm)
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
     * Function명  : fn_ParseTemplateData
     * 설명        : 지문인식기 앱 데이타 파싱
     ******************************************************************************/
    fn_ParseTemplateData: function (pData) {
      let returnData = []
      if (pData.results.length > 0) {
        let iLoopCnt = 0
        pData.results.forEach(
          (item) => {
            let oneTmpl = {}
            oneTmpl.captureMode = String(iLoopCnt) // 촬영모드 : 0=등록, 1=인증, 2서명
            oneTmpl.state = item.state // 처리결과
            oneTmpl.livePstnCd = item.privateInfo.verify_index_finger // 촬영 손가락
            oneTmpl.template = item.template // 암호화된 지문데이타
            oneTmpl.templateHash = item.templateHash // 지문데이타HASH
            oneTmpl.secretKey = item.secretKey // 금결원 공개키 대칭암호화키
            returnData.push(oneTmpl)
            iLoopCnt++
          }
        )
      }
      return returnData
    },
    /************************************************************************************************
     * Function명  : fn_RegTemplateData
     * 설명        : 지문템플릿 데이타 등록요청
     * param  pData1 지문인식기 데이타 (지문 템플릿)
     *        pData2 서비스 구분 및 서비스아이디
     ************************************************************************************************/
    fn_RegTemplateData: function (pData1, pData2, fnCallBack) {
      let lv_Vm = this
      const trnstId = 'txTSSPS41U2'
      const auth = 'S'
      let cbData = {isOk: true} // 콜백 데이타
      let arrData = this.fn_ParseTemplateData(pData1) // 데이타 파싱.
      this.fn_trace('info', '지문템플릿 데이타 파싱완료::', arrData)
      if (typeof arrData === 'object') { // 정상처리 되었고, 정상데이타 리턴시
        cbData.isOk = true
      } else if (typeof arrData === 'boolean') { // 지문인식기 처리 오류의 경우
        cbData.isOk = false
        fnCallBack(cbData)
      }
      let pParams = {
        bioComyCd: this.lv_BioAllData.bioData.bioComyCd, // 생체인식-참여업체코드
        bioScCd: this.lv_BioAllData.bioData.bioScCd, // 생체구분코드
        bioSvcTp: pData2.bioSvcTp, // 서비스구분
        bioSvcId: pData2.bioSvcId, // 서비스ID
        liveAthntCustScCd: '02',
        vuhdInfo: this.lv_BioAllData.vuhdInfo,
        custInfo: this.lv_BioAllData.custInfo,
        listTemplet: arrData
      }
      this.fn_trace('info', '지문템플릿 데이타 등록요청::', pParams)
      try {
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.body) {
            lv_Vm.fn_trace('info', '지문템플릿 데이타 등록요청 결과::', response.body)
            if ( response.body.isProcOk === 'Y' ) {
              fnCallBack({isOk: true})
            } else {
              fnCallBack({isOk: false})
            }
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      } catch (error) {
        window.vue.error(error)
        lv_Vm.fn_trace('info', '지문템플릿 데이타 등록요청::', error)
      }
    },
    /************************************************************************************************
     * Function명  : fn_trace
     * 설명        : 프로그램 에러 로그 공통 함수
     ************************************************************************************************/
    fn_trace: function (type, msgTitle, msgData) {
      // this.$emit('childs-trace-event', type, msgTitle, msgData)
      console.log( msgTitle + ':::' + '----------------------' )
      console.log( msgData)
    },
    // fn_TTTest: function () {
    //   // this.$emit('comp-change-event', '0')
    //   // this.$parent.isDebugMode = true
    //   this.showComponent = true
    //   this.lv_ShowTestCombo = true
    //   console.log( '----------->' + this.test )
    //   if ( parseInt(this.test) > 5 ) {
    //     this.test = '0'
    //   }
    // },
    /************************************************************************************************
     * Function명  : fn_prcsInsrdCnsntResult
     * 설명        : 모바일청약, 피보험자동의 처리
     * 처리내역
     * 1. 모청. 피보험자동의 완료처리
     * 2. 부인방지 처리 (피보험자 생체완료)
     ************************************************************************************************/
    fn_prcsInsrdCnsntResult: function () {
      // 모바일청약, 피보험자 생체인증 결과 저장
      let lv_Vm = this
      let trnstId = 'txTSSPS50I2'
      let auth = 'I'
      let pParams = {insrdCnsntScCd: 'L', vuchId: this.bioParams.vuchId} // 피보험자동의구분코드, L:생체인증
      try {
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.body) {
            let t_IsProcOk = response.body.isProcOk
            if (t_IsProcOk === 'Y') { // 부인방지 정상 처리시.
               lv_Vm.lv_MsgFailFofAcpnConfirm = '지문촬영을 통한 등록/인증이<br>정상처리 되었습니다.'
              // lv_Vm.lv_FailFofAcpnConfirm2 = true
              // lv_Vm.getStore('confirm').dispatch('ADD', '지문촬영을 통한 등록/인증이<br>정상처리 되었습니다.')
              // lv_Vm.fn_FofAcpnCfmResult(true)
              lv_Vm.fn_BottomConfirmY( lv_Vm.lv_MsgFailFofAcpnConfirm);
            } else { // 부인방지 처리 실패.
               lv_Vm.lv_MsgFailFofAcpnConfirm = '지문촬영에 실패하였습니다.<br><span style="color:#FE886A;">피보험자 서면동의서 작성 후<br>서면동의서 촬영 혹은 지점접수</span>를 진행해 주세요.'
              // lv_Vm.lv_FailFofAcpnConfirm2 = true
              // lv_Vm.getStore('confirm').dispatch('ADD', '지문촬영에 실패하였습니다.<br><span style="color:#FE886A;">피보험자 서면동의서 작성 후<br>서면동의서 촬영 혹은 지점접수</span>를 진행해 주세요.')
              // lv_Vm.fn_FofAcpnCfmResult(true)
              lv_Vm.fn_BottomConfirmY( lv_Vm.lv_MsgFailFofAcpnConfirm);
            }
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      } catch (error) {
        window.vue.error(error)
      }
    },

   /******************************************************************************
   * Function명 : fn_FailFofAcpnConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_FailFofAcpnConfirm (conName, contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "확인",
         title_neg_btn: "취소"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_FofAcpnCfmResult(true)
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          this.fn_FofAcpnCfmResult(false)
        }
       }
     })
   },

   /******************************************************************************
   * Function명 : fn_CustCardConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_CustCardConfirm (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "고객카드",
         title_neg_btn: "취소"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_CustCfmResult(true)
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          this.fn_CustCfmResult(false)
        }
       }
     })
   },

   /******************************************************************************
    * Function명 : fn_showTSSCM122D
    * 설명       : 고객개인정보 수정 팝업 열기
    ******************************************************************************/
    fn_showTSSCM122D (targetChnlCustId) {
      let lv_Vm = this

      // 고객카드 수정
      this.rpblPopup = this.$bottomModal.open(MSPCM300P, {
        properties: {
              callerId : this.$options.screenId, 
              viewId : 'MSPCM301D',
              pChnlCustId : this.lv_InsRdChnlCustId,
              pCnsltNo : this.lv_CnsltNo,
        },
        listeners: { 
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose : (rtnData) => {            
            this.fn_CloseCustCardPopup()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기        
          },
          closePopup : (rtnData) => {            
            this.fn_CloseCustCardPopup()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기      
          },
          onPopupConfirm: (rtnData) => {            
            this.fn_CloseCustCardPopup()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기      

          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },

  /******************************************************************************
   * Function명 : fn_ProcPhotoTabletConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_ProcPhotoTabletConfirm (contents) {
     let contentsMsg = '지문 촬영을 진행하시겠습니까?'
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
        //  content: contents,
        content: contentsMsg,
         title_pos_btn: "확인",
         title_neg_btn: "취소"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_TblPhotoCfmResult(true)
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          this.fn_TblPhotoCfmResult(false)
        }
       }
     })
   },

  /******************************************************************************
   * Function명 : fn_RtnPhotoConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_RtnPhotoConfirm (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "네",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_RtnPhotoCfmResult(true)
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          this.fn_RtnPhotoCfmResult(false)
        }
       }
     })
   },
   /******************************************************************************
   * Function명 : fn_RtnPhotoConfirm2
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_RtnPhotoConfirm2 (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "네",
         title_neg_btn: "지점접수"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_RtnPhotoCfmResult2(true)
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          this.fn_RtnPhotoCfmResult2(false)
        }
       }
     })
   },
     /******************************************************************************
   * Function명 : fn_BottomConfirmY
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_BottomConfirmY (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         single: true
       },
       listeners: {
         onPopupConfirm: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          this.fn_FofAcpnCfmResult(true)
         }
       }
     })
   },

    _fn_End(){return}//코딩 종료 함수 메서드 함수 , 없음 주의

 },// methods

}//export default


</script>